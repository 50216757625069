import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import Button from '../../components/Button/Button';
import ToolTip from '../../components/ToolTip/ToolTip';

import OBDLogo from '../../svgs/OBDlogo-greyBg.svg';
import Document from '../../svgs/document-greyBg.svg';
import Hourglass from '../../svgs/hourglass-greyBg.svg';
import Barrier from '../../svgs/barrier-greyBg.svg';
import Keys from '../../svgs/keys-greyBg.svg';
import Crane from '../../svgs/craneHome-greyBg.svg';

const PreConstructionCondoPage = () => {
	const intl = useIntl();
	return (
		<Layout
			view='buyer'
			header={
				<>
					<FormattedMessage id='preConstructionCondo.headerAi' />{' '}
					<span style={{ fontWeight: `bold` }}>
						<FormattedMessage id='preConstructionCondo.headerAii' />
					</span>
				</>
			}
			breadcrumb={{
				text: <FormattedMessage id='buyerNav.dashboard' />,
				path: '/new-home-buyer/dashboard/'
			}}
		>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'buyerNav.preConCondo' })} />
			<Container>
				<Row>
					<Col>
						<p>
							<FormattedMessage id='preConstructionCondo.contentA' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={2}>
						<OBDLogo />
					</Col>

					<Col md={10}>
						<h2 className='sm'>
							<FormattedMessage id='preConstructionCondo.headerB' />
						</h2>
						<p>
							<FormattedMessage id='preConstructionCondo.contentB' />
							<br />
							<br />
							<FormattedMessage id='preConstructionCondo.contentC' />
						</p>
						<Button
							linkExternal
							linkPath='https://obd.hcraontario.ca'
							text={<FormattedMessage id='preConstructionCondo.headerB' />}
						/>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={2}>
						<Document />
					</Col>

					<Col md={10}>
						<h2 className='sm'>
							<FormattedMessage id='preConstructionCondo.headerC' />
						</h2>
						<p>
							<FormattedMessage id='preConstructionCondo.contentD' />
							<br />
							<br />
							<FormattedMessage id='preConstructionCondo.contentE' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={2}>
						<Hourglass />
					</Col>

					<Col md={10}>
						<h2 className='sm'>
							<FormattedMessage id='preConstructionCondo.headerD' />
						</h2>
						<p>
							<FormattedMessage id='preConstructionCondo.contentF' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={2}>
						<Barrier />
					</Col>

					<Col md={10}>
						<h2 className='sm'>
							<FormattedMessage id='preConstructionCondo.headerE' />
						</h2>
						<p>
							<FormattedMessage id='preConstructionCondo.contentG' />{' '}
							<ToolTip
								link='/glossary/#deposit'
								internal
								definition={<FormattedMessage id='glossaryTerms.depositDef' />}
							>
								<FormattedMessage id='glossaryTerms.depositTermB' />
							</ToolTip>{' '}
							<FormattedMessage id='preConstructionCondo.contentH' />{' '}
							<a
								href='https://www.tarion.com/homeowners/condo-units-coverage-occupancy-dates-delays-cancellations'
								aria-label={intl.formatMessage({
									id: 'preConstructionCondo.contentI'
								})}
							>
								<FormattedMessage id='preConstructionCondo.contentI' />
							</a>
							.
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={2}>
						<Keys />
					</Col>

					<Col md={10}>
						<h2 className='sm'>
							<ToolTip
								link='/glossary/#interimOccupancy'
								internal
								definition={<FormattedMessage id='glossaryTerms.interimDef' />}
							>
								<span className='sm'>
									<FormattedMessage id='glossaryTerms.interimTerm' />
								</span>
							</ToolTip>
						</h2>
						<p>
							<FormattedMessage id='preConstructionCondo.contentJ' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={2}>
						<Crane />
					</Col>

					<Col md={10}>
						<h2 className='sm'>
							<FormattedMessage id='preConstructionCondo.headerF' />
						</h2>
						<p>
							<FormattedMessage id='preConstructionCondo.contentK' />
						</p>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default PreConstructionCondoPage;
